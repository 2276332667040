
<template>
  <div class="qrcode">
    <el-image
      v-if="qrcode"
      fit="cover"
      :src="qrcode"
      alt="点击刷新登录"
      class="el-image-box"
      @click="handleCode"
      @load="show = true"
    >
      <template #placeholder>
        <div class="image-slot" v-loading="true" element-loading-text="二维码加载中...">
        </div>
      </template>
      <template #error>
        <div class="image-slot" @click="handleCode">
          <i class="el-icon-picture-outline" />
          <span>加载失败，请点击重试</span>
        </div>
      </template>
    </el-image>
    <div v-else class="fail-tip" v-loading="true" element-loading-text="二维码加载中...">
    </div>
    <div v-if="timeOut" class="fail-tip" @click="handleCode">
      <span>扫码超时<br/>点击重新扫码</span>
    </div>
  </div>
</template>
<script setup>
import { wxQRCodeLogin } from '~/api/user';
import { getWxScanResult } from '~/api/index.js'
const route = useRoute();
const router = useRouter();

const qrcode = ref('');
const show = ref(false);
const timeOut = ref(false);
let timer = null;
const store = useUserStore();
const { loginSuccess } = defineProps({
  loginSuccess: Function
});

onMounted(() => {
  handleCode();
});

onUnmounted(() => {
  clearInterval(timer);
});

const handleCode = async () => {
  const { QRCode, Code } = await wxQRCodeLogin();
  timeOut.value = false;
  show.value = false;
  if (QRCode){
    qrcode.value = QRCode;
    let count = 0;
    timer = setInterval(() => {
      if (count >= 120) {
        clearInterval(timer);
        timeOut.value = true;
        return;
      }
      count++;
      queryResult(Code);
    }, 2000)
  }
};
const queryResult = (code) => {
  getWxScanResult(code).then(res => {
    if (res.S === '1') {
      clearInterval(timer);
      store.setUserInfo(res.UserList[0]);
      if (loginSuccess) {
        loginSuccess();
      } else if (route.query.redirect_url) {
        window.location.replace(route.query.redirect_url)
      } else {
        router.push({ path: '/user' });
      }
    }
  })
}
</script>
<style scoped lang="scss">
.fail-tip {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  filter: blur(0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #050505;
  font-size: 14px;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(6px);
  border-radius: 10px;
  span{
    text-align: center;
    line-height: 24px;
  }
}

.qrcode {
  width: 210px;
  height: 210px;
  background: #f5f6f9;
  padding: 10px;
  border: 1px solid #ededed;
  box-shadow: 0 2px 6px 0 rgba(228, 215, 215, 0);
  border-radius: 4px;
  color: #333;
  margin: 20px auto 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}

.image-slot {
  width: 200px;
  height: 200px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  i {
    font-size: 26px;
  }
}
</style>
