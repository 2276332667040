<template>
  <el-form ref="loginRef"
           :model="loginForm"
           :rules="loginRules"
           class="login-form"
           autocomplete="on"
           label-position="left"
           @keyup.enter="handleLogin(loginRef)" >
    <el-form-item v-if="showForm === 1" prop="username" class="canlder">
      <el-input
        ref="username"
        v-model="loginForm.username"
        size="large"
        placeholder="请输入账号"
        name="username"
        type="text"
        tabindex="1"
        autocomplete="on"
      >
        <template #prefix>
          <UserIcon class="icon"/>
        </template>
      </el-input>
    </el-form-item>

    <el-form-item v-if="showForm !== 1" prop="phone" class="canlder">
      <el-input
        ref="phone"
        v-model="loginForm.phone"
        size="large"
        placeholder="请输入手机号"
        name="phone"
        type="text"
        tabindex="1"
        autocomplete="on"
      >
        <template #prefix>
          <MobileIcon class="icon"/>
        </template>
      </el-input>
    </el-form-item>

    <el-form-item v-if="showForm !== 1" prop="yzm" class="canlder">
      <el-input
        ref="yzm"
        v-model="loginForm.yzm"
        size="large"
        placeholder="请输入验证码"
        name="yzm"
        type="text"
        tabindex="1"
        autocomplete="on"
      >
        <template #prefix>
          <YzmIcon class="icon"/>
        </template>

        <template #suffix>
          <el-button type="primary" link @click="getSmsCode"
                     :disabled="getSmsCodeDisabled || loginForm.phone.length !== 11 ">{{ yzmTips }}</el-button>
        </template>
      </el-input>
    </el-form-item>

    <el-tooltip :visible="capsTooltip" content="⚠️大写键盘已开启" placement="right">
      <el-form-item prop="password" class="canlder">
        <el-input
          :key="passwordType"
          ref="password"
          v-model="loginForm.password"
          size="large"
          show-password
          :type="passwordType"
          placeholder="请输入密码"
          name="password"
          tabindex="2"
          autocomplete="on"
          @keyup="(e) => {capsTooltip = e.key && e.key.length === 1 && e.key >= 'A' && e.key <= 'Z'}"
          @blur="capsTooltip = false"
        >
          <template #prefix>
            <PwdIcon class="icon"/>
          </template>
        </el-input>
      </el-form-item>
    </el-tooltip>

    <el-tooltip v-if="showForm === 3" :visible="capsTooltip2" content="⚠️大写键盘已开启" placement="right">
      <el-form-item prop="againPwd" class="canlder">
        <el-input
          :key="passwordType"
          ref="againPwd"
          v-model="loginForm.againPwd"
          size="large"
          show-password
          :type="passwordType"
          placeholder="请输入密码"
          name="againPwd"
          tabindex="2"
          autocomplete="on"
          @keyup="(e) => {capsTooltip2 = e.key && e.key.length === 1 && e.key >= 'A' && e.key <= 'Z'}"
          @blur="capsTooltip2 = false"
        >
          <template #prefix>
            <PwdIcon class="icon"/>
          </template>
        </el-input>
      </el-form-item>
    </el-tooltip>

    <el-form-item>
      <p class="tips">
        <span v-if="showForm > 1" @click="handleChange(1)">账号密码登录</span>
        <span  v-if="showForm === 1 || showForm === 3" @click="handleChange(2)">忘记密码</span>
        <span  v-if="showForm < 3" @click="handleChange(3)">没有账号？点我注册</span>
      </p>
      <el-button
        type="primary"
        size="small"
        class="login-submit"
        :loading="loading"
        @click.prevent="handleLogin(loginRef)">
         <span v-if="showForm === 1">登录</span>
         <span v-else-if="showForm === 2">找回密码</span>
         <span v-else>立即注册</span>
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import md5 from 'js-md5';
import { accountLogin, updatePwd as forgetPwd, sendSmsCode, accountReg } from '~/api/user';
import UserIcon from '~/assets/svgs/yonghu.svg';
import PwdIcon from '~/assets/svgs/mima.svg';
import MobileIcon from '~/assets/svgs/shouji.svg';
import YzmIcon from '~/assets/svgs/yzm.svg';

const { loginSuccess } = defineProps({
  loginSuccess: {
    type: Function
  }
})

const emit = defineEmits(["updateTitle"]);
const loginForm = reactive({
  username: '',
  phone: '',
  yzm: '',
  password: '',
  againPwd: '',
  md5: '',
});
const yzmTips = ref('获取验证码');
const loginRef = ref(null);
const getSmsCodeDisabled = ref(false);
const loginRules = {
  username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
  phone: [ { required: true, message: "手机号不能为空", trigger: "blur" },
    { pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号", trigger: "blur", }],
  yzm: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
  password: [{ required: true, trigger: 'blur', message: '请输入密码' }],
  againPwd: [{ required: true, trigger: 'blur', message: '请输入密码' },
    {
      validator: (rule, value, callback) => {
        if (!value) {
          callback(new Error("请再次输入密码"));
        } else if (value !== loginForm.password) {
          callback(new Error("两次输入的密码不一致"));
        } else {
          callback();
        }
      },
      trigger: 'blur'
    }],
};

const passwordType = ref('password');
const capsTooltip = ref(false);
const capsTooltip2 = ref(false);
const loading = ref(false);

const route = useRoute();
const router = useRouter();
const store = useUserStore();
const showForm = ref(1); // 表单展示形式 1: 账号密码登录 2: 忘记密码 3: 手机注册

const handleLogin = (formEl) => {
  formEl.validate((valid) => {
    if (valid) {
      loading.value = true;
      login();
    } else {
      return false;
    }
  });
}
const login = () => {
  try {
    loginForm.username = loginForm.username.trim();
    loginForm.password = loginForm.password.trim();
    if (showForm.value === 1) {
      accountLogin({username: loginForm.username, pwd: loginForm.password}).then(res => {
        store.setUserInfo(res.UserList[0]);
        if (loginSuccess) {
          loginSuccess();
        } else {
          if (route.query.redirect_url) {
            window.location.replace(route.query.redirect_url)
          } else {
            router.push({ path: '/user' });
          }
        }
      }).finally(() => {
        loading.value = false;
      })
    } else if (showForm.value === 2) {
      forgetPwd(  {
        mobile: loginForm.phone,
        newpwd: loginForm.password,
        code: loginForm.yzm,
        MD5: loginForm.md5
      }).then(res => {
        ElMessage.success('找回密码成功~');
      }).finally(() => {
        loading.value = false;
      })
    } else if (showForm.value === 3) {
      accountReg({
        mobile: loginForm.phone,
        pwd:  loginForm.password,
        code: loginForm.yzm,
        userfrom: 9,
        MD5: loginForm.md5
      }).then(res => {
        showForm.value = 1;
        ElMessage.success('账号注册成功, 快去登录吧~');
      }).finally(() => {
        loading.value = false;
      })
    }
  } catch (e) {
    loading.value = false;
  }
}
const handleChange = (type) => {
  showForm.value = type;
  let title = 1;
  if (type === 2) {
    title = 'forget'
  } else if (type === 3) {
    title = 'register'
  }
  emit('updateTitle', title);
}
// 获取验证码
const getSmsCode = () => {
  if (loginForm.phone) {
    sendSmsCode({
      Mobile: loginForm.phone,
      flag: showForm === 2 ?  5 : 6,
      MD5: md5(loginForm.phone + "ppkao.com")
    }).then(res => {
      loginForm.md5 = res.MD5Code;
      if (res.S === '1') {
        getSmsCodeDisabled.value = true;
        let time = 59;
        const timer = setInterval(() => {
          if (time > 0) {
            yzmTips.value = time + "s";
            time --;
          } else {
            yzmTips.value = "获取验证码";
            clearInterval(timer);
          }
        }, 1000);
      } else {
        ElMessage.error(res.msg);
      }
    })
  }
  // 获取验证码
}
</script>

<style lang="scss" scoped>

.login-form {
  margin: 10px 0;
  i {
    color: #333;
  }
  .el-form-item {
    margin-bottom: 32px;
  }
  .el-input {
    input {
      padding-bottom: 10px;
      text-indent: 5px;
      background: transparent;
      border: none;
      border-radius: 0;
      color: #333;
      border-bottom: 1px solid rgb(235, 237, 242);
    }
    .el-input__prefix {
      i {
        padding: 0 5px;
        font-size: 16px !important;
      }
    }
  }
  .icon {
    width: 1em;
    height: 1em;
    margin-right: 12px;
  }
}
.login-submit {
  width: 100%;
  height: 45px;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 300;
  cursor: pointer;
  transition: 0.25s;
}
.el-form-item__content {
  width: 100%;
  line-height: initial !important;
}
.login-form {
  margin: 10px 0;
  i {
    color: #333;
  }

  .el-form-item {
    margin-bottom: 12px;
  }
  .el-input {
    input {
      padding: 20px 0 20px 40px;
      text-indent: 5px;
      border-radius: 0;
    }
    .el-input__suffix, .el-input__prefix {
      display: flex;
      align-items: center;
      text-align: center;
    }
    .el-input__prefix {
      margin-left: 8px;
    }
  }
}
.canlder {
  margin: 22px 0 !important;
}
.tips {
  width: 100%;
  @include flex-x-center;
  justify-content: space-between;
  margin-bottom: 10px;
  span {
    @include font(var(--primary-color), 14px);
    text-decoration: underline;
    cursor: pointer;
  }
}

</style>
