<template>
  <div class="login-box">
    <div v-if="showIcon" class="icon-box">
      <img src="https://static.ppkao.com/www/images/login/icon64.png" class="icon"/>
    </div>
    <img src="https://static.ppkao.com/www/images/login/wx-icon.png" v-if="loginMode !== 'wx'" class="change-icon" @click="loginMode = 'wx'"/>
    <img src="https://static.ppkao.com/www/images/login/input-icon.png" v-else class="change-icon" @click="loginMode = 'account'"/>
    <div class="login-form">
      <p class="title">{{ headInfo.title }}</p>
      <p class="tips">{{ headInfo.tips }}</p>
      <div class="scan-box">
          <transition name="fade-transform" mode="out-in">
            <component
              :is="loginModeComponent"
              v-if="loginModeComponent"
              :login-success="loginSuccess"
              @updateTitle="onUpdateTitle"
              :key="loginModeComponent"
            />
          </transition>
        <div v-if="!isAgree && !isRegister" :class="{'scan-tip': true, 'scan-blur': loginMode === 'wxRegister', 'form-blur': loginMode === 'account' }"></div>
      </div>
          <p v-if="isRegister && loginMode !== 'account'" class="policy">
            <span><a @click="loginMode = 'wxRegister'">点击注册账号</a></span>
          </p>
          <p v-else-if="loginMode !== 'account'" class="policy" >
            <el-tooltip placement="bottom" :teleported="false" :offset="6" :visible="!isAgree" content="请阅读之后勾选">
              <el-checkbox v-model="isAgree" style="margin-right: 6px;"/>
            </el-tooltip>
           <span @click="isAgree = !isAgree">我已阅读并同意</span><span><a @click="handleOpenModal(1)">《服务条款》</a>、<a @click="handleOpenModal(2)">《隐私协议》</a></span>
          </p>
    </div>
    <el-dialog
      :title="dialogInfo.title"
      destroy-on-close
      append-to-body
      v-model="dialogVisible"
      width="30%"
      center>
      <p v-html="dialogInfo.richText"  class="rich-text custom-scrollbar"></p>
      <div class="is-read">
        <el-button type="primary" @click="dialogVisible = false">我已阅读</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script setup>
import LoginForm from './Form.vue'
import LoginScan from './Scan.vue'
import { getNewHelp } from '~/api/user';
const { showIcon, loginSuccess } = defineProps({
  showIcon: {
    type: Boolean,
    default: true
  },
  loginSuccess: {
    type: Function
  }
})
const isAgree = ref(false);
const loginMode = ref('wx');
const headInfo = computed(() => {
  switch ( loginMode.value ) {
    case 'wx': return { title: '快捷登录', tips: '微信扫一扫登录' };
    case 'forget': return { title: '安全验证', tips: '为了保证您的账号安全，请进行安全验证' };
    case 'wxRegister': return { title: '快捷注册', tips: '微信扫一扫注册' };
    case 'register': return { title: '账号注册', tips: '验证手机号后注册' };
    default: return { title: '密码登录', tips: '输入账号密码登录' };
  }
})
const isRegister = computed(() => loginMode.value === 'account' || loginMode.value === 'wx' || loginMode === 'forget');
const loginModeComponent = computed(() => {
  if (loginMode.value === 'account') {
    return LoginForm
  }
  if ((isAgree.value && loginMode.value === 'wxRegister') || loginMode.value === 'wx') {
    return LoginScan
  }
  return () =>
    h('div', { class: 'placeholder' }, [
      h('img', {
        src: 'https://static.ppkao.com/public/wxqrcode/pptiku.jpg',
        alt: '扫码关注',
        class: 'qr-code'
      })
    ])
})
const dialogInfo = reactive({
  title: '考试资料网服务条款',
  richText: ''
});
const dialogVisible = ref(false);
const handleOpenModal = (type) => {
  dialogVisible.value = true;
  if (type === 2) {
    dialogInfo.title = '考试资料网隐私协议';
    getNewHelp({TypeID: 9}).then(res => {
      dialogInfo.richText = res.HelpList[0].Analysis
    })
    return;
  }
  dialogInfo.title = '考试资料网服务条款';
  getNewHelp({TypeID: 7}).then(res => {
    dialogInfo.richText = res.HelpList[0].Analysis
  })
}

const onUpdateTitle = (value) => {
  loginMode.value = value;
}
</script>

<style scoped lang="scss">
.scan-box {
  min-height: 250px;
  position: relative;
  @include flex-center;
  .scan-tip {
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    text-align: center;
    margin: 20px auto;
    position: absolute;
    z-index: 10;

    font-size: 14px;
    @include flex-center;
  }
  .scan-blur {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
  }
  .form-blur {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(1px);
  }
}

.login-box {
  width: 380px;
  background: #FFFFFF;
  border-radius: 10px;
  .icon-box {
    position: absolute;
    left: 0;
    top: -28px;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    img {
      border-radius: 50%;
      width: 58px;
      height: 58px;
      background-color: var(--primary-color);
    }
  }
  .change-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  .login-form {
    width: 96%;
    max-width: 340px;
    margin: 0 auto;
    .title {
      margin-top: 42px;
      font-size: 18px;
      font-weight: bold;
      color: #000000;
      text-align: center;
    }
    .tips {
      font-size: 14px;
      font-weight: 400;
      color: #757580;
      margin-top: 10px;
      text-align: center;
    }
  }
  .policy {
    padding-bottom: 12px;
    font-size: 14px;
    color: #757580;
    text-align: center;
    @include flex-center;
    span {
      cursor: pointer;
      font-size: 14px;
      a{
        color: #037AFF;
        text-decoration: underline;
        font-size: 14px;
      }
    }
  }
}
.is-read {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px
}
:deep(.el-dialog--center .el-dialog__body) {
  padding: 20px 10px 10px;
}
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .2s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.rich-text {
  height: 500px;
  overflow-y: scroll;
  ::-webkit-scrollbar { /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }


  :deep(h3) {
    @include font(#333, 16px, bold);
  }
  :deep(p) {
    @include font(#444, 14px);
    margin: 10px 0 10px;
    line-height: 26px;
  }
}
:deep(.qr-code) {
  width: 210px;
  height: 210px;
}
:deep(.el-popper span) {
  font-size: 14px;
}
</style>
